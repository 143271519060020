@import './base/components/ripple-button.scss';
@import 'react-perfect-scrollbar/dist/css/styles.css';

@import './base/fonts.scss';
@import './base/style.scss';

@import './core.scss';
@import './base/bootstrap-extended/_include.scss';
@import './react/libs/autocomplete/autocomplete.scss';
@import './react/libs/tables/react-dataTable-component.scss';
@import './base/core/menu/menu-types/horizontal-menu.scss';

@import './react/libs/toastify/toastify.scss';
@import 'react-contexify/dist/ReactContexify.min.css';
@import './react/libs/context-menu/context-menu.scss';
@import './react/libs/charts/recharts.scss';
@import './react/libs/tables/react-table.scss';
@import './react/libs/swiper/swiper.scss';
